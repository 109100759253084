<template>
	<div id="body">
		<div id="banner">
			<img :src="Url.url">
		</div>
		<div id="cer_box">
			<div id="cer_box_left">
				<div id="cer_flex">
					<icon-svg class='iconclas' iconClass='wanju'></icon-svg>
					<p>业务数字化管理最佳产品</p>
				</div>
				<div id="cer_flex">
					<icon-svg class='iconclas' iconClass='wanju'></icon-svg>
					<p>最简单易用的OA系统</p>
				</div>
				<div id="cer_flex">
					<icon-svg class='iconclas' iconClass='wanju'></icon-svg>
					<p>移动办公领域首选平台</p>
				</div>
				<div id="cer_flex">
					<icon-svg class='iconclas' iconClass='wanju'></icon-svg>
					<p>工程项目管理最佳方案</p>
				</div>
			</div>
			<div id="cer_box_right">
				<div id="cer_box_right_flex">
					<img style="height: 70%; margin-top: 8%;margin-bottom: 5%;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/QY.png">
					<p>企业版</p>
				</div>
				<div id="cer_box_right_flex">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/GC.png">
					<p>工程版</p>
				</div>
				<div id="cer_box_right_flex">
					<img style="height: 77%; margin-bottom: 1%;margin-top: 3%;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MF.png">
					<p>免费体验</p>
				</div>
				<!-- <div id="cer_box_right_flex" @click="Abfun('fo')" style="cursor: pointer;">
					<img style="height: 70%; margin-bottom: 5%;margin-top: 6%;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/us.png">
					<p>联系我们</p>
				</div> -->
			</div>
		</div>
		<div id="oa_mode">
			<div id="oa_mode_title">
				<p>200+应用模块，全面数字化协同办公</p>
				<span>——更有手机APP同步在线，为您量身订制的OA系统</span>
			</div>
			<div id="Oaimg" @click="$router.push('/OAMODE')">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAleft.jpg">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAright.jpg">
			</div>
		</div>
		<div id="flow_DZ">
			<div id="flow_DZ_tit">
				<p>120+电子流程，智能化规范运作</p>
				<span>——强大的数字化智能业务流程，全面超越传统的OA系统</span>
			</div>
			<div id="flow_DZ_mian">
				<div id="flow_DZ_mian_left">
					<img @click="$router.push('/OAMODE')" style="cursor: pointer;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/dzflow.jpg">
				</div>
				<div id="flow_DZ_mian_right">
					<div id="flow_DZ_mian_right_text">
						<p>全面数字化智能流程驱动</p>
						<span @click="$router.push('/personnel')" class="texthov">出差、报销、请假、考勤等等，超过120种业务流程</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>电子单据智能化流转</p>
						<span>衔接多个办公环节和处理步骤，多人多岗无缝协作</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>主动通知和提醒</p>
						<span @click="$router.push('/Mydesktop')" class="texthov">流程运转到哪一步,主动给予桌面和APP提醒</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>辅助填单和规范运作</p>
						<span @click="$router.push('/finance')" class="texthov">辅助用户填写电子单据，按照预定的规则审批流转单据</span>
					</div>
				</div>
			</div>
		</div>
		<div id="flow_DZ">
			<div id="flow_DZ_tit">
				<p>实时移动访问，管理尽在掌中</p>
				<span>——专属于手机APP,让您的办公突破时间和地域限制</span>
			</div>
			<div id="flow_DZ_mian">
				<div id="flow_DZ_mian_left">

					<div id="flow_DZ_mian_right_text">
						<p>24小时在线协作</p>
						<span>PC、笔记本、平板、手机四屏畅通，数据实时同步</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>随时随地移动审批</p>
						<span @click="$router.push('/administrative')" class="texthov">即时获得提醒，按需审阅业务明细，一键审批流转</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>一线人员手机下单</p>
						<span @click="$router.push('/clientrelations')" class="texthov">销售和工程等一线人员，在手机上直接拍照和下单</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>外勤跟踪和定位</p>
						<span @click="$router.push('/personnel')" class="texthov">了解外勤人员的工作动态，评估和改进工作效果</span>
					</div>
				</div>
				<div id="flow_DZ_mian_right">
					<img @click="$router.push('/OAMODE')" style="cursor: pointer;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/DZ02.jpg">
				</div>
			</div>
		</div>
		<div id="flow_DZ">
			<div id="flow_DZ_tit">
				<p>多种创新设计，体验简约简单办公</p>
				<span>——大数据文档存储 助力新一代的OA系统</span>
			</div>
			<div id="flow_DZ_mian">
				<div id="flow_DZ_mian_left">
					<img @click="$router.push('/OAMODE')" style="cursor: pointer;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/DZ03.jpg">
				</div>
				<div id="flow_DZ_mian_right">
					<div id="flow_DZ_mian_right_text">
						<p>平面化、纸张式界面</p>
						<span>聚焦业务和工作本身，所见即所得，能够直接打印</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>飞快的界面响应速度</p>
						<span>采用前后端分离，系统具有更高效，更灵活的运行性能</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>辅助填写业务单据</p>
						<span @click="$router.push('/finance')" class="texthov">自动初始化数据，自动计算求和，自动转换大写金额</span>
					</div>
					<div id="flow_DZ_mian_right_text">
						<p>单据关联和数据同步</p>
						<span>在不同单据之间传递数据和关联，大幅减少操作时间</span>
					</div>
				</div>
			</div>
		</div>
		<div id="sever_box">
			<div id="sever_box_header">
				<p>多年深厚积累，客户口碑更佳</p>
				<span>
					——源自专业的技术团队，打造更实用的超级OA系统
				</span>
			</div>
			<div id="sever_box_main">
				<div id="sever_box_main_left">
					<div id="sever_box_main_left_box">
						<p><i style="color: red;">*</i>专业的技术团队，全新的开发理念打造新一代的移动OA办公</p>
					</div>
					<div id="sever_box_main_left_box">
						<p><i style="color: red;">*</i>专业贴心的售后服务团队，让您购买无忧</p>
					</div>
					<div id="sever_box_main_left_box">
						<p><i style="color: red;">*</i>简单实用的流程管理，让您的办公尽在掌中</p>
					</div>
				</div>
				<div id="sever_box_main_right">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Sever.jpg">
				</div>
			</div>
		</div>
		<div id="architecture_box">
			<div id="architecture_box_header">
				<p>{{Url.name}}</p>
				<span>——打造以移动智能为核心的全新一代OA办公系统</span>
			</div>
			<div id="architecture_box_main">
				<div id="architecture_box_main_left">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/JC.jpg">
				</div>
				<div id="architecture_box_main_right">
					<p><i style="color: red;">●</i>系统集成CRM客户管理模块，为您精准跟踪每个客户的动态</p>
					<p><i style="color: red;">●</i>内置考勤，计划模块让您随时随地掌公司外勤人员的人员位置及工作计划</p>
					<p><i style="color: red;">●</i>系统集成强大的合同管理模块让您通过手机可实时了解到合同的状态</p>
					<p><i style="color: red;">●</i>全面覆盖了公司的财务管理，针对公司报销，费用等审批流程实现科学化的规范及跟踪</p>
				</div>
			</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default {
		components: {
			footers
		},
		computed: {
			monitor () {
				return this.$store.state.OAid
			}
		},
		data() {
			return {
				bannerlist: [{
					id: 1,
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAimg.jpg',
					name: '全面覆盖建筑工程方面的办公的行业难点'
				}, {
					id: 2,
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAbanner02.jpg',
					name: '全面覆盖房地产装饰方面的办公的行业难点'
				},{
					id: 3,
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner03.jpg',
					name: '全面覆盖现代服务方面的办公的行业难点'
				}, {
					id: 4,
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAbanner03.jpg',
					name: '全面覆盖生产制造方面的办公的行业难点'
				}],
				Url: {
					url: '',
					id: '',
					name: ''
				},
				Uid: null
			}
		},
		created() {
			this.Uid = this.$store.state.OAid
			this.BAnfun()
		},
		methods:{
			BAnfun(){
				for (let i = 0; i < this.bannerlist.length; i++) {
					if (this.bannerlist[i].id == this.Uid) {
						this.Url = this.bannerlist[i]
					}
				}
			},
			Abfun(val) { //关于我们
				this.$router.push('/aboutUs')
				this.aboudusflage = false
				this.$store.commit('abtabfun', val)
			},
		},
		watch: {
			monitor () {
					this.Uid = this.$store.state.OAid
					this.BAnfun()
				}
		}
	}
</script>

<style scoped lang="less">
	@media screen and (min-width: 720px) {

		//电脑
		#banner {
			width: 100%;
			height: 700px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#banner {
			width: 100%;
			height: 200px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑
		#cer_box {
			width: 80%;
			height: 160px;
			margin-left: 10%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			#cer_box_left {
				width: 50%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#cer_flex {
					width: 22%;
					height: 100%;
					background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/JX.jpg');
					background-size: 100% 100%;
					text-align: center;
					padding-top: 4%;

					.iconclas {
						font-size: 30px;
						color: #F9CC64;
					}

					p {
						background-image: -webkit-linear-gradient(left bottom, #AD862F, #FACB60);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						width: 50%;
						font-size: 13px;
						margin-left: 27%;
						font-weight: bold;
					}
				}
			}

			#cer_box_right {
				width: 50%;
				height: 100%;
				display: flex;

				#cer_box_right_flex {
					width: 22%;
					height: 100%;
					text-align: center;

					img {
						max-width: 100%;
						height: 80%;
					}

					p {
						font-size: 16px;
						font-weight: bold;
						color: #fd3f31;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#cer_box {
			width: 100%;
			height: 160px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			#cer_box_left {
				width: 100%;
				height: 50%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#cer_flex {
					width: 25%;
					height: 150%;
					background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/JX.jpg');
					background-size: 100% 100%;
					text-align: center;
					padding-top: 4%;

					.iconclas {
						font-size: 15px;
						color: #F9CC64;
					}

					p {
						background-image: -webkit-linear-gradient(left bottom, #AD862F, #FACB60);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						width: 76%;
						font-size: 12px;
						margin-left: 12%;
						font-weight: bold;
					}
				}
			}

			#cer_box_right {
				width: 100%;
				height: 60%;
				margin-top: 3%;
				display: flex;

				#cer_box_right_flex {
					width: 25%;
					height: 100%;
					text-align: center;

					img {
						max-width: 100%;
						height: 80%;
					}

					p {
						font-size: 16px;
						font-weight: bold;
						color: #fd3f31;
					}
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑
		#oa_mode {
			width: 80%;
			height: 600px;
			margin-left: 10%;
			text-align: center;
			padding-top: 2%;
			margin-top: 3%;

			#oa_mode_title {
				p {
					font-size: 30px;
					font-weight: bold;
				}

				span {
					font-size: 20px;
					font-weight: bold;
					color: #8e9f9e;
				}
			}

			#Oaimg {
				width: 100%;
				height: 80%;
				cursor: pointer;

				img {
					height: 100%;
					max-width: 50%;
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#oa_mode {
			margin-top: 20%;

			#oa_mode_title {
				p {
					font-size: 22px;
					font-weight: bold;
					color: #FD3f31;
				}

				span {
					font-size: 16px;
					font-weight: bold;
					color: #8e9f9e;
				}
			}

			#Oaimg {
				width: 100%;
				height: 80%;

				img {
					height: 100%;
					max-width: 100%;
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑
		#flow_DZ {
			width: 80%;
			margin-left: 10%;
			height: 500px;
			text-align: center;
			padding-top: 2%;
			margin-top: 3%;

			#flow_DZ_tit {
				p {
					font-size: 30px;
					font-weight: bold;
				}

				span {
					font-weight: bold;
					font-size: 20px;
					color: #8e9f9e;
				}
			}

			#flow_DZ_mian {
				width: 80%;
				height: 80%;
				margin-left: 10%;
				display: flex;

				#flow_DZ_mian_left {
					width: 50%;
					height: 100%;
					text-align: right;

					img {
						height: 100%;
						max-width: 100%;
					}

					#flow_DZ_mian_right_text {
						width: 100%;
						height: 25%;
						padding-top: 2%;

						p {
							font-size: 22px;
							font-weight: bold;
						}

					}
				}

				#flow_DZ_mian_right {
					width: 50%;
					height: 100%;

					img {
						height: 100%;
						max-width: 100%;
					}

					#flow_DZ_mian_right_text {
						width: 100%;
						height: 25%;
						padding-top: 2%;

						p {
							font-size: 22px;
							font-weight: bold;
						}

						span {
							color: ;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#flow_DZ {
			width: 100%;
			text-align: center;
			padding-top: 2%;
			margin-top: 5%;
			padding-bottom: 5%;
			box-shadow: 4px 1px 10px #d4c9c9;

			#flow_DZ_tit {
				p {
					font-size: 22px;
					font-weight: bold;
					color: #FD3f31;
				}

				span {
					font-weight: bold;
					font-size: 16px;
					color: #8e9f9e;
				}
			}

			#flow_DZ_mian {
				width: 100%;
				height: 100%;

				#flow_DZ_mian_left {
					width: 100%;
					height: 100%;
					text-align: right;

					img {
						height: 100%;
						max-width: 100%;
					}

					#flow_DZ_mian_right_text {
						width: 100%;
						height: 25%;
						padding-top: 2%;
						text-align: center;

						p {
							font-size: 16px;
							font-weight: bold;
							color: #FD3f31;
						}

						span {
							color: #837f7d;
						}
					}
				}

				#flow_DZ_mian_right {
					width: 100%;
					height: 100%;
					margin-top: 2%;

					img {
						height: 100%;
						max-width: 100%;
					}

					#flow_DZ_mian_right_text {
						width: 100%;
						height: 25%;
						padding-top: 2%;

						p {
							font-size: 16px;
							font-weight: bold;
							color: #FD3f31;
						}

						span {
							color: #837f7d;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑
		#sever_box {
			width: 80%;
			margin-left: 10%;
			height: 600px;
			margin-top: 3%;
			padding-top: 2%;

			#sever_box_header {
				text-align: center;

				p {
					font-size: 32px;
					font-weight: bold;
				}

				span {
					color: #8e9f9e;
					font-size: 20px;
				}
			}

			#sever_box_main {
				width: 100%;
				height: 77%;
				margin-top: 2%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#sever_box_main_left {
					width: 50%;
					height: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;

					#sever_box_main_left_box {
						width: 100%;
						height: 33%;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						font-size: 24px;

						p {
							margin: 0;
						}
					}
				}

				#sever_box_main_right {
					width: 50%;
					height: 100%;

					img {
						height: 100%;
						max-width: 100%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#sever_box {
			width: 100%;
			margin-top: 3%;
			padding-top: 2%;
			box-shadow: 4px 1px 10px #d4c9c9;

			#sever_box_header {
				text-align: center;

				p {
					font-size: 22px;
					font-weight: bold;
					color: #FD3f31;
				}

				span {
					color: #8e9f9e;
				}
			}

			#sever_box_main {
				width: 100%;
				height: 77%;
				margin-top: 4%;

				#sever_box_main_left {
					width: 100%;
					height: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;

					#sever_box_main_left_box {
						width: 100%;
						height: 33%;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						font-size: 18px;

						p {
							margin: 0;
							color: #837f7d;
						}
					}
				}

				#sever_box_main_right {
					width: 100%;
					height: 100%;
					margin-top: 2%;

					img {
						height: 100%;
						max-width: 100%;
					}
				}
			}
		}
	}

	@media screen and (min-width: 720px) {

		//电脑
		#architecture_box {
			width: 80%;
			height: 600px;
			margin-top: 3%;
			margin-left: 10%;
			text-align: center;
			margin-bottom: 5%;

			#architecture_box_header {
				p {
					font-size: 32px;
					font-weight: bold;
				}

				span {
					color: #8e9f9e;
					font-size: 20px;
				}
			}

			#architecture_box_main {
				width: 100%;
				height: 76%;
				margin-top: 3%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#architecture_box_main_left {
					width: 50%;
					height: 100%;

					img {
						max-width: 100%;
						height: 100%;
					}
				}

				#architecture_box_main_right {
					width: 50%;
					height: 100%;

					p {
						width: 100%;
						height: 25%;
						margin: 0;
						font-size: 18px;
						display: flex;
						justify-content: flex-start;
						align-items: center;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#architecture_box {
			width: 100%;
			margin-top: 5%;
			text-align: center;
			margin-bottom: 5%;
			box-shadow: 4px 1px 10px #d4c9c9;

			#architecture_box_header {
				p {
					font-size: 22px;
					font-weight: bold;
					color: #FD3f31;
				}

				span {
					color: #8e9f9e;
					font-size: 16px;
				}
			}

			#architecture_box_main {
				width: 100%;
				height: 76%;
				margin-top: 5%;

				#architecture_box_main_left {
					width: 100%;
					height: 100%;

					img {
						max-width: 100%;
						height: 100%;
					}
				}

				#architecture_box_main_right {
					width: 100%;
					height: 100%;

					p {
						width: 100%;
						height: 25%;
						color: #837f7d;
						margin: 0;
						display: flex;
						justify-content: flex-start;
						align-items: center;
					}
				}
			}
		}
	}

	.texthov:hover {
		color: #fd3f31;
		cursor: pointer;
	}
</style>
